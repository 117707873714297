import { YuLifeLocale } from "hooks";
import { StaticRoutes } from "./StaticRoutes";

export const staticRoutesByLocale: Record<StaticRoutes, YuLifeLocale[]> = {
  [StaticRoutes.error]: ["uk", "us", "za", "jp"],
  [StaticRoutes.aboutUs]: ["uk", "us", "za"],
  [StaticRoutes.aig]: ["uk"],
  [StaticRoutes.adviser]: ["uk"],
  [StaticRoutes.adviserContactUs]: ["uk"],
  [StaticRoutes.adviserSuccess]: ["uk"],
  [StaticRoutes.blog]: ["uk", "us", "za"],
  [StaticRoutes.careers]: ["uk", "us", "za"],
  [StaticRoutes.caseStudies]: ["uk", "us", "za"],
  [StaticRoutes.contactUs]: ["uk", "us", "za"],
  [StaticRoutes.deathInService]: ["uk"],
  [StaticRoutes.featuresEmployeeApp]: ["uk", "us", "za"],
  [StaticRoutes.featuresEmployeeAssistanceProgramme]: ["uk", "za"],
  [StaticRoutes.featuresEmployeeAssistanceProgram]: ["us"],
  [StaticRoutes.featuresEmployeeBenefits]: ["uk", "us", "za"],
  [StaticRoutes.featuresEmployeeEngagement]: ["uk", "us"],
  [StaticRoutes.featuresHrInsights]: ["uk", "us", "za"],
  [StaticRoutes.featuresPortal]: ["uk", "us", "za"],
  [StaticRoutes.featuresRewards]: ["uk", "us", "za"],
  [StaticRoutes.featuresGlobalRewardsAndServices]: ["uk", "us", "za"],
  [StaticRoutes.featuresSocialImpactBenefits]: ["uk", "us", "za"],
  [StaticRoutes.featuresVirtualGpServices]: ["uk", "za"],
  [StaticRoutes.featuresWellbeingHub]: ["uk", "us", "za"],
  [StaticRoutes.featuresYustore]: ["uk"],
  [StaticRoutes.formProductDetails]: ["uk"],
  [StaticRoutes.formSuccess]: ["uk"],
  [StaticRoutes.getAQuote]: ["uk", "us", "za"],
  [StaticRoutes.getAQuoteSuccess]: ["uk", "us", "za"],
  [StaticRoutes.groupInsuranceForms]: ["za"],
  [StaticRoutes.hrInsights]: ["uk"],
  [StaticRoutes.iframeGroupLifeForm]: ["uk"],
  [StaticRoutes.iframeQuoteForm]: ["uk"],
  [StaticRoutes.index]: ["uk", "us", "za"],
  [StaticRoutes.inspireLives]: ["uk"],
  [StaticRoutes.insuranceAppOnly]: ["uk", "us"],
  // [StaticRoutes.insuranceEmployerSponsoredInsurance]: ["us"],
  [StaticRoutes.insuranceGroupCriticalIllness]: ["uk", "za"],
  [StaticRoutes.insuranceGroupDentalInsurance]: ["uk"],
  [StaticRoutes.insuranceGroupIncomeProtection]: ["uk", "za"],
  [StaticRoutes.insuranceGroupInsurance]: ["uk", "za"],
  [StaticRoutes.insuranceGroupLifeInsurance]: ["uk", "za"],
  // [StaticRoutes.insuranceVoluntaryInsurance]: ["us"],
  [StaticRoutes.insuranceGroupHealthInsurance]: ["uk"],
  // [StaticRoutes.insuranceGroupVisionInsurance]: ["us"],
  // [StaticRoutes.insuranceGroupDisabilityInsurance]: ["us"],
  [StaticRoutes.insuranceFuneralCover]: ["za"],
  [StaticRoutes.insuranceLumpSumDisability]: ["za"],
  [StaticRoutes.insuranceEducationBenefit]: ["za"],
  [StaticRoutes.newsAndEvents]: ["uk", "us", "za"],
  [StaticRoutes.ourSolutionsBrokersAndAdvisers]: ["us"],
  [StaticRoutes.ourSolutionsBusinesses]: ["us"],
  [StaticRoutes.ourSolutionsIndividuals] : ["us"],
  [StaticRoutes.ourSolutionsInsuranceCarriers] : ["us"],
  [StaticRoutes.personalLifeInsurance]: ["uk"],
  [StaticRoutes.press]: ["uk", "us", "za"],
  [StaticRoutes.privateHealthInsurance]: ["uk"],
  [StaticRoutes.quizLeaderPersonality]: ["uk"],
  [StaticRoutes.resourceHub]: ["uk", "us", "za"],
  [StaticRoutes.rewardsRedeemedCharityWater]: ["uk", "us"],
  [StaticRoutes.rewardsRedeemedEarthly]: ["uk", "us"],
  [StaticRoutes.rewardsRedeemedFoodForward]: ["uk", "us"],
  [StaticRoutes.rewardsRedeemedOceanCleanup]: ["uk", "us"],
  [StaticRoutes.rewardsRedeemedCheckEmail]: ["uk"],
  [StaticRoutes.rewardsRedeemedShareTheMeal]: ["uk", "us"],
  [StaticRoutes.rewardsRedeemedYoyoVoucher]: ["uk", "us"],
  [StaticRoutes.team]: ["uk", "us", "za"],
  [StaticRoutes.whatsIncluded]: ["uk", "us"],
  [StaticRoutes.wills]: ["uk"],
  [StaticRoutes.ycp20200117Calm]: ["uk"],
  [StaticRoutes.ycp20210423Farewill]: ["uk"],
};
